import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { capitalizeFirstLetterAndLowercaseRest } from '@bridebook/toolbox/src/utils/strings';
import { Box, ButtonV2, Well } from '@bridebook/ui';
import { IconMail } from '@bridebook/ui/src/icons';
import AuthCountrySelect from 'components/auth/country-select/auth-country-select';
import AuthButton from 'components/auth/signup-container/components/auth-buttons/auth-button/auth-button';
import AuthButtons from 'components/auth/signup-container/components/auth-buttons/auth-buttons';
import SignupLoginForm from 'components/auth/signup-container/components/signup-login-form/signup-login-form';
import Title from 'components/auth/signup-container/components/title/title';
import { getCustomAuthMessages } from 'lib/auth/custom-auth-messages';
import { getFirebaseMessages } from 'lib/auth/firebase-messages';
import {
  getAuthForm,
  getAuthFormDisabled,
  isCollaboratorInvite,
  isCountrySelected,
} from 'lib/auth/selectors';
import {
  AuthBridebookError,
  AuthProviders,
  CustomAuthErrorCodes,
  FirebaseErrorCodes,
} from 'lib/auth/types';
import { IIntlMessageDescriptor } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { createReverseMappingForEnum, useSelector } from 'lib/utils';
import styles from './signup-component.style';

interface IProps {
  showCollaborationAnimationVisible?(show: boolean): void;
}

const SignupComponent = ({ showCollaborationAnimationVisible }: IProps) => {
  const { t } = useTranslation('signup');
  const isCollaboratorsInvite = useSelector(isCollaboratorInvite);
  const countrySelected = useSelector(isCountrySelected);
  const authFormDisabled = useSelector(getAuthFormDisabled);
  const [showSocialAuthButtons, setShowSocialAuthButtons] = useState(true);
  const router = useRouter();
  const {
    query: { next },
  } = router;
  const authForm = useSelector(getAuthForm);
  const formError = authForm.error as AuthBridebookError;
  const reverseAuthProviders = createReverseMappingForEnum(AuthProviders);
  const authError = useMemo(() => {
    if (formError?.prop === 'email' && formError.code) {
      const oneOfFirebaseErrors: IIntlMessageDescriptor =
        getFirebaseMessages()[formError.code as FirebaseErrorCodes];
      const oneOfCustomErrors: IIntlMessageDescriptor =
        getCustomAuthMessages()[formError.code as CustomAuthErrorCodes];
      if (oneOfFirebaseErrors) {
        return oneOfFirebaseErrors.defaultMessage;
      }
      if (oneOfCustomErrors) {
        return oneOfCustomErrors.defaultMessage;
      }
    }
    if (formError?.prop === 'email') return t('wrongEmail');
    if (formError?.code === 'auth/account-exists-with-different-credential' && formError?.provider)
      return t('accountUsedWithDifferentProvider', {
        provider: capitalizeFirstLetterAndLowercaseRest(reverseAuthProviders[formError.provider]),
      });

    return undefined;
  }, [formError, reverseAuthProviders, t]);

  const toggleSocialAuthButtonsState = useCallback(() => {
    setShowSocialAuthButtons((prevState) => !prevState);
  }, []);

  const toggleSignupMode = useCallback(() => {
    const nextParam = typeof next === 'string' ? `&next=${encodeURIComponent(next)}` : '';

    const navigateUrl = `${UrlHelper.login}${nextParam}`;

    router.push(navigateUrl);
  }, [next, router]);

  return (
    <>
      {!isCollaboratorsInvite && (
        <Box style={styles.countrySelector}>
          <AuthCountrySelect />
        </Box>
      )}
      <Title socialAuthVisible={showSocialAuthButtons} />
      <Box gap={3}>
        {!isCollaboratorsInvite && <Box style={styles.startPlanning}>{t('letsStartPlanning')}</Box>}
        {showSocialAuthButtons ? (
          <>
            <AuthButton
              onClick={toggleSocialAuthButtonsState}
              text={t('signupWithEmail')}
              icon={<IconMail color="white" width={18} />}
              dataTest="continue-with-email"
              disabled={!countrySelected || authFormDisabled}
              variant="primary"
            />
            {authError && (
              <Box as={'p'} style={styles.error}>
                {authError}
              </Box>
            )}
            <AuthButtons showCollaborationAnimationVisible={showCollaborationAnimationVisible} />
          </>
        ) : (
          <>
            <SignupLoginForm
              showCollaborationAnimationVisible={showCollaborationAnimationVisible}
            />
            <ButtonV2 onClick={toggleSocialAuthButtonsState} variant="link">
              {t('otherMethod')}
            </ButtonV2>
          </>
        )}
      </Box>
      {isCollaboratorsInvite && (
        <Well slim theme="peachRose">
          {showSocialAuthButtons
            ? t('collaborator.welcome.warning')
            : t('collaborator.signup.warning')}
        </Well>
      )}
      <ButtonV2 variant="link" onClick={toggleSignupMode}>
        {t('alreadyHaveAnAccount')} {t('logIn')}
      </ButtonV2>
    </>
  );
};

export default memo(SignupComponent);
