import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  startPlanning: FelaCSS;
  countrySelector: FelaCSS;
  error: FelaCSS;
}

const styles: IStyles = {
  startPlanning: {
    fontDefaultSemiBold: 14,
  },
  countrySelector: {
    justifySelf: 'flex-end',
  },
  error: {
    paddingTop: 1,
    paddingHorizontal: 4,
    fontDefaultSemiBold: 12,
    color: colors.alerts,
    maxWidth: '100%',
    margin: 0,
  },
};

export default styles;
